import logger from 'ws-scripts/modules/logger';
import { isBrowser } from 'ws-scripts/modules/environment';
import { hysteriFetch, wrappers } from 'hysterics';
import { setLoading } from '../actions/setLoading';
import { setVehicles } from '../actions/setVehicles';
import { addUrlSearchParams } from '../utils/addUrlSearchParams';

const timeout = 1600;

const requestModelsWrapper = hysteriFetch(
	'ws-model-selector-request-get-model-selector',
	{
		[wrappers.circuitBreaker.contextKey]: {
			timeout
		},
		[wrappers.timeout.contextKey]: {
			timeout
		}
	}
);

export const requestModelSelectorData = async (
	dispatch,
	{
		make,
		siteId,
		pageAlias,
		imagePerspective,
		oemCustomMappingId,
		showInventoryOnLotCount = true,
		enableModelSelectorService = false,
		excludeModelsWithoutInventory = false,
		enableGroupInventoryConstraints = false,
		locale
	}
) => {
	const rootPath = '/api/widget';
	let endpoint = '';

	if (enableModelSelectorService && make && siteId) {
		const url = new URL(
			`${rootPath}/ws-model-selector/vehicles`,
			isBrowser ? window.location.origin : 'http://localhost'
		);

		addUrlSearchParams(url.searchParams, {
			siteId,
			make,
			showInventoryOnLotCount,
			excludeModelsWithoutInventory,
			imagePerspective,
			oemCustomMappingId,
			enableGroupInventoryConstraints,
			locale
		});
		endpoint = url.toString();
	} else if (!enableModelSelectorService && pageAlias) {
		endpoint = `/apis/widget/${pageAlias}:model-selector-data-bus1/getModelSelectorData?imagePerspective=${imagePerspective}`;

		let searchParams;

		if (isBrowser)
			searchParams = new URLSearchParams(window.location.search);
		else searchParams = new URLSearchParams();

		const ddcpreview = searchParams.get('_ddcpreview');

		if (ddcpreview) {
			endpoint = `${endpoint}&_ddcpreview=${ddcpreview}`;
		}

		if (excludeModelsWithoutInventory) {
			endpoint = `${endpoint}&excludeModelsWithoutInventory=${excludeModelsWithoutInventory}`;
		}

		if (showInventoryOnLotCount) {
			endpoint = `${endpoint}&showInventoryOnLotCount=${showInventoryOnLotCount}`;
		}
	} else {
		const loggedError = new Error(
			`Unable to make request with pageAlias:${pageAlias}, make:${make}, siteId:${siteId}`
		);
		logger.error(loggedError);
		dispatch(
			setLoading({
				status: false,
				error: loggedError
			})
		);
	}

	return requestModelsWrapper(endpoint)
		.then((vehicles) => {
			dispatch(setVehicles(vehicles));
		})
		.catch((error) => {
			logger.error(error);
			dispatch(setLoading({ error }));
		})
		.finally(() => {
			dispatch(setLoading({ status: false }));
		});
};
