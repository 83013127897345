import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRequestData, usePrefs, useFlags, useLabels } from 'wsm-common-data';
import { Error } from './Error';
import { Loading } from './Loading';
import { TabbedBodyStyles } from './TabbedBodyStyles';
import { requestModelSelectorData } from '../data/modelSelectorServiceFetcher';
import {
	loadingSelector,
	widgetDataSelector,
	vehiclesSelector
} from '../server/selectors';

export const DataFetcher = () => {
	const {
		displayStyle,
		showInventoryOnLotCount,
		excludeModelsWithoutInventory,
		oemCustomMappingId,
		enableGroupInventoryConstraints
	} = usePrefs();

	const flags = useFlags();
	const labels = useLabels();
	const dispatch = useDispatch();
	const { deviceType, locale } = useRequestData();
	const loading = useSelector(loadingSelector);
	const vehicles = useSelector(vehiclesSelector);
	const { siteId, pageAlias, make } = useSelector(widgetDataSelector);

	const enableModelSelectorService = flags['enable-model-selector-service'];

	const imagePerspective =
		deviceType === 'MOBILE' || displayStyle === 'grid'
			? 'side-left'
			: 'front-left';

	useEffect(() => {
		requestModelSelectorData(dispatch, {
			make,
			siteId,
			pageAlias,
			imagePerspective,
			showInventoryOnLotCount,
			enableModelSelectorService,
			excludeModelsWithoutInventory,
			oemCustomMappingId,
			enableGroupInventoryConstraints,
			locale
		});
	}, [
		dispatch,
		make,
		siteId,
		pageAlias,
		imagePerspective,
		showInventoryOnLotCount,
		enableModelSelectorService,
		excludeModelsWithoutInventory,
		oemCustomMappingId,
		enableGroupInventoryConstraints,
		locale
	]);

	if (loading.status && !loading.error) {
		return <Loading />;
	} else if (
		(!loading.status && loading.error) ||
		Object.keys(vehicles).length === 0
	) {
		return <Error message={labels.get('MODEL_SELECTOR_UNAVAILABLE')} />;
	} else {
		return <TabbedBodyStyles data={vehicles} />;
	}
};
