import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';

import { ModelImage } from '../ModelImage';
import { getModelTitle } from '../../utils/getModelTitle';
import { getFormattedPrice } from '../../utils/getFormattedPrice';

const MobileCarouselCard = ({ modelObject, modelLink }) => {
	const prefs = usePrefs();
	const labels = useLabels();
	const { locale, widgetName, windowId } = useRequestData();

	const { image, inventoryCount } = modelObject;
	const {
		showInventoryOnLotCount,
		modelTitleAttributes,
		inventoryCountLabel,
		startingAtPriceLabel,
		showPricing
	} = prefs;

	const showInventoryCount = showInventoryOnLotCount === 'true';
	const modelClassNames = [
		'model',
		'model-card-title-container',
		showInventoryCount ? 'showing-count' : null,
		'p-4',
		'd-flex',
		'flex-1',
		'flex-column',
		'justify-content-between'
	];
	const modelTitleClassName = [
		'font-weight-bold',
		'ddc-font-size-large',
		'd-flex',
		'justify-content-start'
	];
	const priceClassNames = [
		'price',
		'ddc-font-size-small',
		'inline-block',
		'justify-content-start',
		'd-flex'
	];
	const modelTitle = getModelTitle(modelTitleAttributes, modelObject);
	const formattedPrice = getFormattedPrice(locale, modelObject);

	const trackClick = () => {
		trackEvent(widgetName, windowId, {
			action: 'clicked',
			element: 'Model Image',
			elementCTA: modelTitle,
			result: 'Navigated to model listings'
		});
	};

	return (
		<a
			href={modelLink}
			onClick={trackClick}
			className="mobile-card position-relative box box-border"
		>
			<div className={setClassNames(modelClassNames)}>
				<div className="d-flex flex-column model-card-title-container">
					<span className={setClassNames(modelTitleClassName)}>
						{modelTitle}
					</span>
					{showPricing === 'true' && (
						<span className={setClassNames(priceClassNames)}>
							{`${labels.get(startingAtPriceLabel)} `}{' '}
							{formattedPrice}
						</span>
					)}
				</div>

				<div className="image-container py-7">
					<ModelImage url={image} altImage={modelTitle} />
				</div>

				<span className="font-weight-extended-bold ddc-font-size-small text-center">
					{showInventoryCount && (
						<div className="touch-inventory-count ddc-font-size-xsmall font-weight-bold">
							{inventoryCount}
							<span className="inventory-count-label">
								{` ${labels.get(inventoryCountLabel)}`}
							</span>
						</div>
					)}
				</span>
			</div>
		</a>
	);
};

MobileCarouselCard.propTypes = {
	modelObject: PropTypes.shape({
		year: PropTypes.number,
		make: PropTypes.string,
		model: PropTypes.string,
		image: PropTypes.string,
		inventoryCount: PropTypes.number,
		minStartingAtPrice: PropTypes.number
	}).isRequired,
	modelLink: PropTypes.string.isRequired
};

export default MobileCarouselCard;
